import React from "react";
import MenuIcon from '@mui/icons-material/Menu';
import { Button, useTheme, useMediaQuery, Box, Typography } from '@mui/material';

 
const Header = ({ setModalIsOpen, setSideMenuOpen, sideMenuOpen }) => {
const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


  const handleSideMenuClick = () => {
    setSideMenuOpen(!sideMenuOpen);
  };

  return (
    <header className="headerContainer">
      <Box 
      className="hamburgerCnt" 
      onClick={handleSideMenuClick}
      style={{position: "relative", height: "65px", width: "65px"}}
      >
       {!sideMenuOpen && <MenuIcon className="hamburgerMenu" fontSize="lg" sx={{zIndex: "1", width: "50px", height: "50px", position: "absolute", top: '12px', left: "13px", textAlign: "center"}} />}
      </Box>
      <Typography variant="h4" justifySelf={"center"}>Payment Tracker</Typography>

    </header>
  );
};

export default Header;
