import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import PaymentModal from "../PaymentModal/PaymentModal";
import SideMenu from "../SideMenu/SideMenu";
import { Link, Outlet } from "react-router-dom";
import ChildProfileModal from "../ChildProfileModal/ChildProfileModal";
import WriteOffModal from "../WriteOffModal/WriteOffModal";
import HourlyModal from "../HourlyModal/HourlyModal";

const Layout = ({
  children,
  setModalIsOpen,
  isOpen,
  setTableData,
  tableData,
  url,
  refreshTable,
  setSideMenuOpen,
  sideMenuOpen,
  menuStyle,
  parentSwitchStates,
  childSwitchStates,
  parentData,
  childData,
  childProfileModalOpen,
  setChildProfileModalOpen,
  childProfile,
  setChildProfile,
  parentChildData,
  taxModal,
  setTaxModal,
  taxData,
  setTaxData,
  hourlyModalOpen,
  setHourlyModalOpen,
  hourlyData,
  setHourlyData,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <SideMenu sideMenuOpen={sideMenuOpen} setSideMenuOpen={setSideMenuOpen} menuStyle={menuStyle} />
      <div
style={{
  ...(windowWidth < 600
    ? { marginLeft: 0 }
    : sideMenuOpen
      ? { marginLeft: "calc(100% - 80%)" }
      : windowWidth < 2120
        ? { marginLeft: "75px" }
        : { margin: "auto", maxWidth: "2000px" }),
  transition: "margin-left 300ms ease-in-out",
}}

  >
        <Header setSideMenuOpen={setSideMenuOpen} sideMenuOpen={sideMenuOpen} />
        {children}
        <ChildProfileModal
          setChildProfileModalOpen={setChildProfileModalOpen}
          childProfileModalOpen={childProfileModalOpen}
          url={url}
          parentData={parentData}
          childData={childData}
          childProfile={childProfile}
          setChildProfile={setChildProfile}
          refreshTable={refreshTable}
          parentChildData={parentChildData}
        />
        <PaymentModal
          isOpen={isOpen}
          setModalIsOpen={setModalIsOpen}
          setTableData={setTableData}
          tableData={tableData}
          url={url}
          refreshTable={refreshTable}
          parentSwitchStates={parentSwitchStates}
          childSwitchStates={childSwitchStates}
          parentData={parentData}
          childData={childData}
          setTaxModal={setTaxModal}
        />
        <WriteOffModal
          taxModal={taxModal}
          setTaxModal={setTaxModal}
          url={url}
          refreshTable={refreshTable}
        />
        <HourlyModal
          hourlyModalOpen={hourlyModalOpen}
          setHourlyModalOpen={setHourlyModalOpen}
          url={url}
          refreshTable={refreshTable}
        />
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
