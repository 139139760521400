import React, { useState } from "react";
import {
  Button,
  Box,
  Stack,
  TextField,
  Typography,
  FormControl,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const HourlyModal = ({
  hourlyData,
  setHourlyModalOpen,
  hourlyModalOpen,
  url,
  refreshTable,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [hourly, setHourly] = useState({
    event: "",
    employee: "",
    date: "",
    hours: "",
    pay: "",
  });

  const handleChange = (e, prevInfo, key) => {
    setHourly({ ...prevInfo, [key]: e.target.value });
  };

  const styles = {
    ...(hourlyModalOpen ? { display: "block" } : { display: "none" }),
  };

  const handleSubmit = () => {
    const { event, employee, date, hours, pay } = hourly;
    const hourlyData = {
      event: event,
      employeeName: employee,
      date: date,
      hoursWorked: parseFloat(hours).toFixed(2),
      hourlyPay: parseFloat(pay).toFixed(2),
    };

    fetch(`${url}/hourly_pay`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(hourlyData),
    })
      .then((res) => {
        if (!res.ok) {
          alert("something went wrong");
        }
        return res.json();
      })
      .then((data) => {
        console.log("Hourly Pay Added:", data);
        // setHourly({
        //     event: '',
        //     employee: '',
        //     date: '',
        //     hours: '',
        //     pay: ''
        // });
      })
      .catch((error) => {
        alert("Couldnt Add Hourly Pay", error);
      });
    refreshTable();
    setHourlyModalOpen(false);
  };

  return (
    <Stack className="modalOverlay" style={styles}>
      <Box className="hourlyModal" style={{...styles, position: 'relative'}}>
        <Box className="modalForm">
          <Box display={"grid"} gridTemplateColumns={"15% 70% 15%"}>
            <Typography
              sx={{ textAlign: "center", margin: 3, gridColumn: "2 / 3" }}
              variant={isMobile ? "h5" : "h4"}
            >
              Hourly Pay
            </Typography>
            <CloseIcon
              onClick={() => setHourlyModalOpen(false)}
              sx={{
                margin: 2,
                padding: 1,
                position: "absolute",
                right: 1,
                top: 1,
                borderRadius: "50%",
                "&:hover": {
                  bgcolor: "grey",
                  color: "white",
                  cursor: "pointer",
                },
              }}
            />
          </Box>

          <FormControl sx={{ gap: isMobile ? 1 : 2 }}>
            <Typography variant="h6">Event</Typography>
            <TextField
              id="outlined-basic"
              label="Event"
              variant="outlined"
              value={hourly.event}
              onChange={(e) => handleChange(e, hourly, "event")}
            />
            <Typography variant="h6">Employee Name</Typography>
            <TextField
              id="outlined-basic"
              label="Employee Name"
              variant="outlined"
              value={hourly.employee}
              onChange={(e) => handleChange(e, hourly, "employee")}
            />
            <Typography variant="h6">Event Date</Typography>
            <TextField
              type="date"
              id="outlined-basic"
              variant="outlined"
              value={hourly.date}
              onChange={(e) => handleChange(e, hourly, "date")}
            />
            <Typography variant="h6">Hours Worked</Typography>
            <TextField
              type="text"
              id="outlined-basic"
              label="Hours Worked"
              variant="outlined"
              value={`${hourly.hours}`}
              onChange={(e) => handleChange(e, hourly, "hours")}
            />
            <Typography variant="h6">Pay Rate</Typography>
            <TextField
              type="text"
              id="outlined-basic"
              label="Pay Rate"
              variant="outlined"
              value={`${hourly.pay}`}
              onChange={(e) => handleChange(e, hourly, "pay")}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{
                margin: "0 auto",
              ...(isMobile ? { margin: "20px auto" } : {}),
            }}
          >
            Add Hourly Pay
          </Button>
        </Box>
      </Box>
    </Stack>
  );
};

export default HourlyModal;
