import React, { useState } from "react";
import {
  TextField,
  Box,
  Typography,
  Select,
  MenuItem,
  Divider,
  Card,
  CardContent,
  Grid,
  Switch,
  FormControlLabel,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";

const Childs = ({
  sideMenuOpen,
  url,
  setChildSwitchStates,
  setChildProfile,
  childSwitchStates,
  setChildProfileModalOpen,
  childData,
  parentData,
  refreshTable,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [value, setValue] = useState("1");

  const handleOpenChildProfileModal = (id) => {
    setChildProfileModalOpen(true);

    const child = childData.find((child) => child.id === id);

    if (child) {
      setChildProfile(child);
    }
  };

  const handleChange = (childId, event) => {
    event.stopPropagation();

    const childIndex = childData.findIndex((child) => child.id === childId);
    if (childIndex === -1) {
      console.error(`Child with ID ${childId} not found.`);
      return;
    }

    const isActive = !childData[childIndex].isActive;

    fetch(`${url}/children/${childId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ isActive: isActive }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to update child isActive status");
        }

        console.log(`Child ${childId} isActive status updated successfully`);

        const updatedChildData = [...childData];
        updatedChildData[childIndex].isActive = isActive;
        setChildSwitchStates(updatedChildData.map((child) => child.isActive));
        refreshTable();
      })
      .catch((error) => {
        console.error("Error updating child isActive status:", error);
      });
  };

  const handleDelete = (childId) => {
    fetch(`${url}/children/${childId}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to delete child");
        }

        console.log(`Child ${childId} deleted successfully`);
        refreshTable();
      })
      .catch((error) => {
        console.error("Error deleting child:", error);
      });
  };

  return (
    <>
      <Box
        sx={{
          ...(isMobile
            ? { display: "flex", flexDirection: "column", alignItems: "center" }
            : {
                display: "flex",
                flexDirection: "row-reverse",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
              }),
        }}
      >
        <TextField
          fullWidth
          label="Search For Child"
          sx={{ maxWidth: "80%", zIndex: -1 }}
        />
        <Select
          sx={{
            ...(isMobile ? { width: "80%", margin: "20px 0 10px 0" } : {}),
          }}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        >
          <MenuItem value="1">Active Children</MenuItem>
          <MenuItem value="2">Inactive Children</MenuItem>
        </Select>
      </Box>
      <Divider sx={{ my: "20px", ...(sideMenuOpen ? { mx: "15px" } : {}) }} />
      <Box>
        {value === "1" ? (
          <Box
            sx={{
              ...(isMobile ? { maxHeight: "100%", overflowY: "scroll" } : {}),
            }}
          >
            <Grid container spacing={2}>
              {childData &&
                childData.map((child) => {
                  const parent = parentData.find(
                    (parent) => parent.id === child.parent_id
                  );

                  const birthday = moment(child.birthday, "YYYY-MM-DD");
                  const age = moment().diff(birthday, "years");

                  return (
                    child.isActive && (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        key={child.id}
                        onDoubleClick={() =>
                          handleOpenChildProfileModal(child.id)
                        }
                        {...(isMobile
                          ? {
                              onLongPress: () =>
                                handleOpenChildProfileModal(child.id),
                            }
                          : {})}
                      >
                        <Card
                          sx={{
                            width: "95%",
                            maxWidth: isMobile ? "95%" : 400,
                            mx: "auto",
                          }}
                        >
                          <CardContent>
                            <Typography>
                              {child.firstName} {child.lastName}
                            </Typography>
                            <Typography>{age} years</Typography>
                            <Typography>
                              {moment(child.birthday).format("MMMM DD, YYYY")}
                            </Typography>
                            {parent && (
                              <>
                                <Typography>{parent.streetAddress}</Typography>
                                <Typography>
                                  {parent.city}, {parent.state} {parent.zipCode}
                                </Typography>
                              </>
                            )}
                            <FormControlLabel
                              control={
                                <Switch
                                  name={child.firstName}
                                  onChange={(event) =>
                                    handleChange(child.id, event)
                                  }
                                  checked={child.isActive}
                                />
                              }
                              label="Active Status"
                            />
                            <IconButton onClick={() => handleDelete(child.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </CardContent>
                        </Card>
                      </Grid>
                    )
                  );
                })}
            </Grid>
          </Box>
        ) : value === "2" ? (
          <Box>
            <Grid container spacing={2}>
              {childData &&
                childData.map((child) => {
                  const parent = parentData.find(
                    (parent) => parent.id === child.parent_id
                  );

                  const birthday = moment(child.birthday, "YYYY-MM-DD");
                  const age = moment().diff(birthday, "years");

                  return (
                    !child.isActive && (
                      <Grid item xs={12} sm={6} md={4} key={child.id}>
                        <Card
                          sx={{
                            width: "95%",
                            maxWidth: isMobile ? "100%" : 400,
                            mx: "auto",
                          }}
                        >
                          <CardContent>
                            <Typography>
                              {child.firstName} {child.lastName}
                            </Typography>
                            <Typography>{age} years</Typography>
                            <Typography>
                              {moment(child.birthday).format("MMMM DD, YYYY")}
                            </Typography>
                            {parent && (
                              <>
                                <Typography>{parent.streetAddress}</Typography>
                                <Typography>
                                  {parent.city}, {parent.state} {parent.zipCode}
                                </Typography>
                              </>
                            )}
                            <FormControlLabel
                              control={
                                <Switch
                                  name={child.firstName}
                                  onChange={(event) =>
                                    handleChange(child.id, event)
                                  }
                                  checked={child.isActive}
                                />
                              }
                              label="Active Status"
                            />
                            <IconButton onClick={() => handleDelete(child.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </CardContent>
                        </Card>
                      </Grid>
                    )
                  );
                })}
            </Grid>
          </Box>
        ) : null}
      </Box>
      <Divider sx={{ my: "20px", ...(sideMenuOpen ? { mx: "15px" } : {}) }} />
    </>
  );
};

export default Childs;
