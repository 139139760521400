import React, { useState } from "react";
import TableData from "./TableData";
import WriteOffTable from "./WriteOffTable";
import { Button, Tab, Tabs, Box, useTheme, useMediaQuery, Select, MenuItem} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import HourlyTable from "./HourlyTable";

const PaymentTable = ({
  tableData,
  url,
  setTableData,
  refreshTable,
  setModalIsOpen,
  taxData,
  setTaxData,
  taxModal,
  setTaxModal,
  hourlyData,
  setHourlyData,
  setHourlyModalOpen,
  hourlyModalOpen
}) => {
  const [value, setValue] = useState("1");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handlePaymentModal = () => {
    setModalIsOpen(true);
  };

  const handleWriteOffModal = () => {
    setTaxModal(true);
  };

  const handleHourlyModal = () => {
    setHourlyModalOpen(true);
    console.log("hourlyModalOpen", hourlyModalOpen);
  };
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSelectChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className="BodyCnt">
      <Box>
        <Box sx={{ width: "100%", typography: "body1",}}>
          <TabContext value={value}>
            <Box
              display={"flex"}
              justifyContent={isMobile ? "space-around" : "space-between"}
              alignItems={"center"}
              maxWidth={"1900px"}
              sx={{ width: "100%", borderBottom: 1, borderColor: "divider", margin: "0 auto"}}
            >
              {isMobile ? (
                <Select value={value} onChange={handleSelectChange}>
                  <MenuItem value="1">Parent Payments</MenuItem>
                  <MenuItem value="2">Tax Write Offs</MenuItem>
                  <MenuItem value="3">Hourly Pay</MenuItem>
                </Select>
              ) : (
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Parent Payments" value="1" />
                  <Tab label="Tax Write Offs" value="2" />
                  <Tab label="Hourly Pay" value="3" />
                </TabList>
              )}
              <Box
                display={"flex"}
                justifyContent={"flex-end"}
                mt={{ xs: 2, sm: 0 }}
              >
                {value === "1" && (
                  <Button
                    className="addPayment"
                    variant="contained"
                    sx={{ mr: "1%", mb: "15px" }}
                    onClick={handlePaymentModal}
                  >
                    Add Payment
                  </Button>
                )}
                {value === "2" && (
                  <Button
                    className="addPayment"
                    variant="contained"
                    sx={{ mr: "1%", mb: "15px" }}
                    onClick={handleWriteOffModal}
                  >
                    Add Tax Write Off
                  </Button>
                )}
                {value === "3" && (
                  <Button
                    className="addPayment"
                    variant="contained"
                    sx={{ mr: "1%", mb: "15px" }}
                    onClick={handleHourlyModal}
                  >
                    Add Hourly Pay
                  </Button>
                )}
              </Box>
            </Box>
            <TabPanel value="1">
              <div className="tableContainer">
                <TableData
                  tableData={tableData}
                  url={url}
                  setTableData={setTableData}
                  refreshTable={refreshTable}
                />
              </div>
            </TabPanel>
            <TabPanel value="2">
              <div className="tableContainer">
                <WriteOffTable
                  tableData={tableData}
                  url={url}
                  setTableData={setTableData}
                  refreshTable={refreshTable}
                  taxData={taxData}
                  setTaxData={setTaxData}
                />
              </div>
            </TabPanel>
            <TabPanel value="3">
              <div className="tableContainer">
                <HourlyTable
                  tableData={tableData}
                  url={url}
                  hourlyData={hourlyData}
                  setHourlyModalOpen={setHourlyModalOpen}
                  setHourlyData={setHourlyData}
                  refreshTable={refreshTable}
                />
              </div>
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </div>
  );
};

export default PaymentTable;
