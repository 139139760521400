import React from "react";
import { Link } from "react-router-dom";
import PaymentTable from "../Table/PaymentTable";
import HomeIcon from "@mui/icons-material/Home";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import { Box, Stack, Typography, useTheme, useMediaQuery } from "@mui/material";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import CloseIcon from "@mui/icons-material/Close";
import Logout from "../Logout";

const SideMenu = ({ sideMenuOpen, setSideMenuOpen }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const sideMenuStyle = {
    width: isMobile ? (sideMenuOpen ? "110vw" : "0") : (sideMenuOpen ? "20%" : "75px"), // Adjusted width for desktop
    opacity: 1, // Ensure visibility
    pointerEvents: "auto", // Ensure interaction
    minWidth: isMobile ? "0" : (sideMenuOpen ? "270px" : "75px"),
    transition: "width 300ms ease-in-out",
  };


  const iconStyle = {
    fontSize: "40px",
    display: isMobile && !sideMenuOpen ? "none" : "block",
    // Adjust margin based on sideMenuOpen
  };

  const liStyles = {
    margin: "5px 0",
  };

  // Use the adjusted style in your side menu component
  return (
    <div className="sideMenu" style={sideMenuStyle}>
      <Box sx={{ textAlign: "right" }}>
        {<CloseIcon
         onClick={() => (setSideMenuOpen(!sideMenuOpen))}
         sx={{
          display: (isMobile && !sideMenuOpen) || (!isMobile && !sideMenuOpen) ? "none" : "inline-block",
          color: "#fff",
          padding: "6px",
          cursor: "pointer",
          margin: "0 10px",
          borderRadius: "50%",
          "&:hover": {
            bgcolor: "white",
            color: "blue",
            transition: "ease-in-out .5s",
          },
        }}
        />}
      </Box>
      <Box>
        <Stack style={{ marginTop: "50px", marginLeft: "15px" }}>
          {sideMenuOpen && (
            <Typography variant="h4" sx={{ color: "#fff" }} fontWeight={"bold"}>
              Dashboard
            </Typography>
          )}
          <ul
            style={{
              listStyle: "none",
              padding: "0",
              display: "flex",
              flexDirection: "column",
              textAlign: "right",
              ...(sideMenuOpen ? { margin: "40px auto" } : { padding: "0px" }),
            }}
          >
            <li style={liStyles}>
              {/* Adjust the Stack direction to "row" and add spacing */}
              <Link onClick={() => (setSideMenuOpen(false))} to="/" style={{ textDecoration: "none" }}>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ display: "flex", alignItems: "center", color: "#fff" }}
                >
                  <HomeIcon sx={iconStyle} />
                  {sideMenuOpen && (
                    <Typography
                      variant="h6"
                      sx={{
                        marginRight: "80px",
                        marginTop: "0px",
                        fontSize: "1.5em",
                        color: "#fff",
                      }}
                    >
                      Home
                    </Typography>
                  )}
                </Stack>
              </Link>
            </li>
            <li style={liStyles}>
              {/* Adjust the Stack direction to "row" and add spacing */}
              <Link onClick={() => (setSideMenuOpen(false))} to="/parents" style={{ textDecoration: "none" }}>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  sx={{ display: "flex", alignItems: "center", color: "#fff" }}
                >
                  <EscalatorWarningIcon sx={iconStyle} />
                  {sideMenuOpen && (
                    <Typography
                      variant="h6"
                      sx={{
                        whiteSpace: "nowrap",
                        marginRight: "80px",
                        marginTop: "0px",
                        fontSize: "1.5em",
                        color: "#fff",
                      }}
                    >
                      Parents
                    </Typography>
                  )}
                </Stack>
              </Link>
            </li>
            <li style={liStyles}>
              {/* Adjust the Stack direction to "row" and add spacing */}
              <Link onClick={() => (setSideMenuOpen(false))} to="/children" style={{ textDecoration: "none" }}>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  sx={{ display: "flex", alignItems: "center", color: "#fff" }}
                >
                  <ChildCareIcon sx={iconStyle} />
                  {sideMenuOpen && (
                    <Typography
                      variant="h6"
                      sx={{
                        whiteSpace: "nowrap",
                        marginRight: "80px",
                        marginTop: "0px",
                        fontSize: "1.5em",
                        color: "#fff",
                      }}
                    >
                      Children
                    </Typography>
                  )}
                </Stack>
              </Link>
            </li>
            <li style={liStyles}>
              {/* Adjust the Stack direction to "row" and add spacing */}
              <Link onClick={() => (setSideMenuOpen(false))} to="/new_enrollment" style={{ textDecoration: "none" }}>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  sx={{ display: "flex", alignItems: "center", color: "#fff" }}
                >
                  <AddToPhotosIcon sx={iconStyle} />
                  {sideMenuOpen && (
                    <Typography
                      variant="h6"
                      sx={{
                        whiteSpace: "nowrap",
                        marginRight: "80px",
                        marginTop: "0px",
                        fontSize: "1.5em",
                        color: "#fff",
                      }}
                    >
                      New Enrollment
                    </Typography>
                  )}
                </Stack>
              </Link>
            </li>
          </ul>
          <Box sx={{color: "#fff"}}>
            <Logout 
            iconStyle={iconStyle}
            sideMenuIsOpen={sideMenuOpen}
            />
          </Box>
        </Stack>
      </Box>
    </div>
  );
};
export default SideMenu;
