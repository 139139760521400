import React, { useState } from "react";
import {
  Button,
  Box,
  Stack,
  TextField,
  Typography,
  FormControl,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const WriteOffModal = ({ taxModal, setTaxModal, url, refreshTable }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [writeOff, setWriteOff] = useState({
    item: "",
    store: "",
    date: "",
    amount: "",
  });

  const handleChange = (e, prevInfo, key) => {
    setWriteOff({ ...prevInfo, [key]: e.target.value });
  };

  const handleSubmit = () => {
    // Destructure writeOff directly inside the function to get the latest state
    const { item, store, date, amount } = writeOff;
    const writeOffData = {
      itemPurchased: item,
      storePurchasedFrom: store,
      purchaseDate: date,
      amount: (parseFloat(amount) || 0).toFixed(2),
    };

    fetch(`${url}/writeoffs`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(writeOffData), // Use writeOffData directly here
    })
      .then((res) => {
        if (!res.ok) {
          alert("something went wrong");
        }
        return res.json();
      })
      .then((data) => {
        console.log("Write Off Added:", data);
        // Clear form after successful submission
        setWriteOff({
          item: "",
          store: "",
          date: "",
          amount: "",
        });
      })
      .catch((error) => {
        alert("Couldnt Add Write Off", error);
      });
    refreshTable();
  };

  const styles = {
    ...(taxModal ? { display: "block" } : { display: "none" }),
   
  };
  return (
    <Stack className="modalOverlay" style={styles}>
      <Box className="writeOffsModal" style={styles}>
        <Box className="modalForm">
          <Box
            display={"grid"}
            gridTemplateColumns={"15% 70% 15%"}
            sx={{ width: "100%" }}
          >
            <Typography
              sx={{ justifySelf: "center", margin: 1, gridColumn: "2 / 3" }}
              variant={isMobile ? "h5" : "h4"}
            >
              Add Write Off
            </Typography>
            <CloseIcon
              onClick={() => setTaxModal(false)}
              sx={{
                position: "absolute",
                right: "10px",
                top: "10px",
                padding: 1,
                borderRadius: "50%",
                "&:hover": {
                  bgcolor: "grey",
                  color: "white",
                  cursor: "pointer",
                },
              }}
            />
          </Box>

          <FormControl sx={{ gap: 1 }}>
            <Typography variant="h6">Expense</Typography>
            <TextField
              id="outlined-basic"
              label="Expense"
              variant="outlined"
              value={writeOff.item}
              onChange={(e) => handleChange(e, writeOff, "item")}
            />
            <Typography variant="h6">Expense Type</Typography>
            <TextField
              id="outlined-basic"
              label="Expense Type"
              variant="outlined"
              value={writeOff.store}
              onChange={(e) => handleChange(e, writeOff, "store")}
            />
            <Typography variant="h6">Dated Purchased</Typography>
            <TextField
              type="date"
              id="outlined-basic"
              variant="outlined"
              value={writeOff.date}
              onChange={(e) => handleChange(e, writeOff, "date")}
            />
            <Typography variant="h6">Expense Amount</Typography>
            <TextField
              type="text"
              id="outlined-basic"
              label="Expense Amount"
              variant="outlined"
              value={`${writeOff.amount}`}
              onChange={(e) => handleChange(e, writeOff, "amount")}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{ margin: "20px auto" }}
          >
            Add Write Off
          </Button>
        </Box>
      </Box>
    </Stack>
  );
};

export default WriteOffModal;
