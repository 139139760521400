import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import {
  Box,
  Button,
  Select,
  MenuItem,
  TextField,
  Typography,
  Stack,
  useTheme, 
  useMediaQuery,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const PaymentModal = ({
  isOpen,
  setModalIsOpen,
  url,
  refreshTable,
  childData,
  parentData,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [modalStyle, setModalStyle] = useState({});
  const [paymentInformation, setPaymentInformation] = useState({
    childName: "",
    parentName: "",
    date: getCurrentDate(),
    paymentAmount: "",
    paymentSource: "",
    paymentSourceLabel: "",
  });

  useEffect(() => {
    if (isOpen) {
      setModalStyle({ display: "block" });
    } else {
      setModalStyle({});
    }
  }, [isOpen]);

  useEffect(() => {
    setPaymentInformation((prevState) => ({
      ...prevState,
      date: getCurrentDate(),
    }));
  }, []);

  const handleClose = () => {
    setPaymentInformation({
      childName: "",
      parentName: "",
      date: getCurrentDate(),
      paymentAmount: "",
      paymentSource: "",
      paymentSourceLabel: "",
    });
    setModalIsOpen(false);
  };

  const formatPaymentAmount = (value) => {
    value = value.replace(/[$,]/g, "");

    if (!isNaN(value)) {
      const amount = parseFloat(value);

      return amount % 1 === 0 ? `$${amount}.00` : `$${amount.toFixed(2)}`;
    }

    return value;
  };

  const handleChange = (key, value) => {
    if (key === "paymentAmount" && typeof value !== "string") {
      value = "";
    }
    setPaymentInformation((prevPaymentInfo) => ({
      ...prevPaymentInfo,
      [key]: value,
    }));

    // Auto-Select the corresponding parent when a child is Selected
    if (key === "childName") {
      const SelectedChild = childData.find(
        (child) => `${child.firstName} ${child.lastName}` === value
      );
      if (SelectedChild) {
        const correspondingParent = parentData.find(
          (parent) => parent.id === SelectedChild.parent_id
        );
        if (correspondingParent) {
          setPaymentInformation((prevPaymentInfo) => ({
            ...prevPaymentInfo,
            parentName: `${correspondingParent.firstName} ${correspondingParent.lastName}`,
          }));
        }
      }
    }
  };

  const handleRadioChange = (e) => {
    setPaymentInformation({
      ...paymentInformation,
      paymentSource: e.target.id,
      paymentSourceLabel: e.target.nextElementSibling.textContent,
    });
  };

  const handleSubmit = () => {
    const childFullName = paymentInformation.childName.trim(); // Get child's full name and trim whitespace
    const parentFullName = paymentInformation.parentName.trim(); // Get parent's full name and trim whitespace
    const paymentDate = paymentInformation.date; // Get payment date
    const paymentAmount = formatPaymentAmount(paymentInformation.paymentAmount); // Format payment amount
    const paymentType = paymentInformation.paymentSourceLabel; // Get payment source

    const requestBody = {
      childFirstName: childFullName.split(" ")[0],
      childLastName: childFullName.split(" ")[1],
      parentFirstName: parentFullName.split(" ")[0],
      parentLastName: parentFullName.split(" ")[1],
      paymentDate,
      paymentAmount,
      paymentType,
    };

    fetch(`${url}/payments`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        } else {
          refreshTable();
        }
        return response.json();
      })

      .catch((error) => {
        console.error("Error:", error);
      });

    if (paymentType === "Cash App") {
      fetch(`${url}/writeoffs`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          itemPurchased: "Cash App Fee",
          storePurchasedFrom: parentFullName,
          purchaseDate: paymentDate,
          amount: parseFloat(paymentAmount.slice(1)) * 0.0275,
        }),
      })
        .then((response) => {
          refreshTable();
        })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }

    setPaymentInformation({
      childName: "",
      parentName: "",
      date: getCurrentDate(),
      paymentAmount: "",
      paymentSource: "",
      paymentSourceLabel: "",
    });
    handleClose();
  };

  const date = {
    day: new Date().getDate(),
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  };

  const currentDate = `${date.month}/${date.day}/${date.year}`;

  return (
    <div className="modalOverlay" style={{...isOpen ? modalStyle : undefined}}>
      <Box className="addPaymentModal" style={{...modalStyle, position: 'relative'}}>
        <Box className="addPaymentHeader" sx={{display: "grid", gridTemplateColumns: "1fr 4fr 1fr", py: 3}}>
          <Typography sx={{gridColumn: "2 / 3", justifySelf: "center"}} variant={isMobile ? "h5" : "h4"}>Add Payment</Typography>
          <ClearIcon
            onClick={() => handleClose()}
            sx={{ cursor: "pointer", margin: "10px", position: "absolute", right: "10px", padding: '5px', borderRadius: "50%", top: "10px", '&:hover': {color: 'white', bgcolor: "grey",}}}
          />
        </Box>
        <Stack className="form">
          <Box className="childName">
            <Typography>Child Name</Typography>
            {childData && (
              <Select
              fullWidth={isMobile}
                name="childName"
                value={paymentInformation.childName} // Ensure this state is defined and updated correctly
                onChange={(e) => handleChange("childName", e.target.value)}
                displayEmpty // Allows the first item to be selectable even if its value is empty
              >
                <MenuItem value="">Select a Child</MenuItem>{" "}
                {/* Assign an empty value for the default option */}
                {childData.map(
                  (child) =>
                    child.isActive && (
                      <MenuItem
                        key={child.id}
                        value={`${child.firstName} ${child.lastName}`}
                      >
                        {`${child.firstName} ${child.lastName}`}
                      </MenuItem>
                    )
                )}
              </Select>
            )}
          </Box>

          <Box className="parentName">
            <Typography>Parent name</Typography>
            {parentData && (
              <Select
              fullWidth={isMobile}
                name="parentName"
                value={paymentInformation.parentName}
                onChange={(e) => handleChange("parentName", e.target.value)}
                displayEmpty 
              >
                <MenuItem value="">Select a Parent</MenuItem>
                {parentData.map((parent) => (
                  parent.isActive && (
                  <MenuItem
                    key={parent.id}
                    value={`${parent.firstName} ${parent.lastName}`}
                  >{`${parent.firstName} ${parent.lastName}`}</MenuItem>)
                ))}
              </Select>
            )}
          </Box>

          <Box className="paymentDate">
            <Typography>Payment Date</Typography>
            <TextField
              fullWidth={isMobile}
              type="date"
              id="paymentDate"
              value={paymentInformation.date}
              onChange={(e) => handleChange("date", e.target.value)}
            />
          </Box>

          <Box className="paymentAmount">
            <Typography>Payment Amount</Typography>
            <TextField
               fullWidth={isMobile}
              type="text"
              id="paymentAmount"
              value={paymentInformation.paymentAmount}
              onChange={(e) => handleChange("paymentAmount", e.target.value)}
            />
          </Box>

          <div className="paymentSourceContainer">
            <Typography>Payment Source</Typography>
            <input
              type="radio"
              id="cashApp"
              name="paymentSource"
              onChange={handleRadioChange}
              checked={paymentInformation.paymentSource === "cashApp"}
            />
            <label htmlFor="cashApp">Cash App</label>
            <br />
            <input
              type="radio"
              id="cash"
              name="paymentSource"
              onChange={handleRadioChange}
              checked={paymentInformation.paymentSource === "cash"}
            />
            <label htmlFor="cash">Cash</label>
            <br />
            <input
              type="radio"
              id="applePay"
              name="paymentSource"
              onChange={handleRadioChange}
              checked={paymentInformation.paymentSource === "applePay"}
            />
            <label htmlFor="applePay">Apple Pay</label>
          </div>
          <Button variant="contained" sx={{width: "60%", margin: "0 auto"}} onClick={handleSubmit}>
            Add Payment
          </Button>
        </Stack>
      </Box>
    </div>
  );
};

export default PaymentModal;
