import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Stack,
  TextField,
  Button,
  FormControl,
  InputLabel,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import AuthorizedPickupModal from "./AuthorizedPickupModal";

const ChildProfileModal = ({
  childData,
  parentChildData,
  refreshTable,
  childProfile,
  setChildProfile,
  parentData,
  url,
  setChildProfileModalOpen,
  childProfileModalOpen,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [editMode, setEditMode] = useState(false);
  const [child, setChild] = useState({
    firstName: "",
    lastName: "",
    birthday: "",
    em_contact_fn: "",
    em_contact_ln: "",
    em_contact_ph: "",
  });
  const [parentInfo, setParentInfo] = useState([]);
  const [authorizedPickupOpen, setAuthorizedPickupOpen] = useState(false);

  useEffect(() => {
    const filteredParentInfo = parentChildData.filter(
      (parent) => parent.child_id === childProfile.id
    );

    const updatedParentInfo = filteredParentInfo.map((parent) => ({
      parentFirstName: parent.parentFirstName,
      parentLastName: parent.parentLastName,
      parentPhone: parent.phoneNumber,
      parentEmail: parent.email,
      parentAddress: parent.address,
      parentCity: parent.city,
      parentState: parent.state,
      parentZip: parent.zipCode,
    }));

    setParentInfo(updatedParentInfo);
  }, [parentChildData, childProfile.id]);

  const fetchData = async (id) => {
    await fetch(`${url}/children/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setChildProfile(data);
      });
  };

  const handleClose = () => {
    setChildProfileModalOpen(false);
    refreshTable();
  };

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleSave = async () => {
    // Create a new object to hold only the modified fields
    const updatedFields = {};
    for (const key in child) {
      if (child.hasOwnProperty(key)) {
        if (child[key] !== "" && child[key] !== childProfile[key]) {
          updatedFields[key] = child[key];
        }
      }
    }

    // Make the PATCH request only if there are updated fields
    if (Object.keys(updatedFields).length > 0) {
      try {
        const response = await fetch(`${url}/children/${childProfile.id}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedFields),
        });

        if (!response.ok) {
          throw new Error("Failed to save changes");
        }

        const data = await response.json();

        // Update childProfile state with the new data received from the server
        await fetchData(childProfile.id);
        await refreshTable();

        // Exit edit mode
        setEditMode(false);
      } catch (error) {
        console.error("Error saving changes:", error);
        // Handle the error appropriately, e.g., display an error message to the user
      }
    } else {
      // No fields were modified, so simply exit edit mode
      setEditMode(false);
    }
  };

  const birthday = moment(childProfile.birthday, "YYYY-MM-DD").toDate(); // Convert to Date object
  const currentDate = new Date();

  let age = currentDate.getFullYear() - birthday.getFullYear();
  const monthDiff = currentDate.getMonth() - birthday.getMonth();

  // If the current month is before the birth month or if it's the same month but the current day is before the birth day
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && currentDate.getDate() < birthday.getDate())
  ) {
    age--;
  }

  const handlePickUpModal = () => {
    setAuthorizedPickupOpen(!authorizedPickupOpen);
  };

  return (
    <Box
      className="modalOverlay"
      style={{
        ...(childProfileModalOpen ? { display: "block" } : { display: "none" }),
      }}
    >
      {!editMode ? (
        <Box
          className="childProfileModal"
          sx={{
            ...(childProfileModalOpen ? { display: "block" } : { display: "none" }), height: isMobile ? "80%" : "auto", overflowY: "scroll"
          }}
        >
          <Box
            display={"grid"}
            sx={{
              width: "100%",
              gridTemplateColumns: isMobile ? "1fr" : "15% 70% 15%",
              alignItems: "center",
              marginBottom: isMobile ? "0px" : 0,
            }}
          >
            <Typography
              sx={{
                gridColumn: isMobile ? "1 / -1" : "2 /3",
                borderRadius: "5px",
                width: isMobile ? "100%" : "71.75%",
                marginLeft: isMobile ? 0 : "12.5%",
                marginBottom: isMobile ? "10px" : 0,
              
              }}
              fontWeight={"bold"}
              variant={isMobile ? "h6" : "h4"}
              align="center"
            >
              {childProfile.firstName} {childProfile.lastName}
            </Typography>
            {!isMobile && (
              <Box
                display={"flex"}
                flexDirection={"column"}
                sx={{ width: "100%", alignItems: "flex-end" }}
              >
                <Button
                  sx={{ marginTop: 2, width: 10, marginRight: "20px" }}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </Button>
                <Button
                  sx={{ marginTop: 2, width: 10, marginRight: "20px" }}
                  onClick={handleEditMode}
                  variant="contained"
                >
                  Edit
                </Button>
              </Box>
            )}
          </Box>
          <Stack
            sx={{
              padding: "10px",
              marginBottom: isMobile ? "60px" : 0,
            }}
          >
            <Box >
              <Box>
                <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                  <Typography
                    sx={{
                      padding: "0 10px",
                      textAlign: "left",
                      borderRadius: "5px",
                      width: isMobile ? "90%" : "70%",
                      margin: isMobile ? "20px auto 0" : "15px auto 0",
                      border: "1px solid #CBCBCB",
                    }}
                    variant="subtitle1"
                    align="center"
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel
                        shrink
                        sx={{
                          bgcolor: "white",
                          px: 1,
                        }}
                      >
                        Birthday:{" "}
                      </InputLabel>
                    </FormControl>
                    {moment(childProfile.birthday).format("MMMM DD, YYYY")}
                  </Typography>
                  <Typography
                    sx={{
                      padding: "0 10px",
                      textAlign: "left",
                      borderRadius: "5px",
                      width: isMobile ? "90%" : "70%",
                      margin: isMobile ? "0 auto" : "0 auto",
                      border: "1px solid #CBCBCB",
                    }}
                    variant="subtitle1"
                    align="center"
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel
                        shrink
                        sx={{
                          bgcolor: "white",
                          px: 1,
                        }}
                      >
                        Age:
                      </InputLabel>
                    </FormControl>{" "}
                    {age}
                  </Typography>
                  <Typography
                    sx={{
                      padding: "0 10px",
                      textAlign: "left",
                      borderRadius: "5px",
                      width: isMobile ? "90%" : "70%",
                      margin: isMobile ? "0 auto" : "0 auto",
                      border: "1px solid #CBCBCB",
                    }}
                    variant="subtitle1"
                    align="center"
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel
                        shrink
                        sx={{
                          bgcolor: "white",
                          px: 1,
                        }}
                      >
                        Emergency Contact:
                      </InputLabel>
                    </FormControl>
                    {childProfile.em_contact_fn} {childProfile.em_contact_ln}
                  </Typography>
                  <Typography
                    sx={{
                      padding: "0 10px",
                      textAlign: "left",
                      borderRadius: "5px",
                      width: isMobile ? "90%" : "70%",
                      margin: isMobile ? "0 auto" : "0 12.5%",
                      border: "1px solid #CBCBCB",
                    }}
                    variant="subtitle1"
                    align="center"
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel
                        shrink
                        sx={{
                          bgcolor: "white",
                          px: 1,
                        }}
                      >
                        Emergency Contact Phone:
                      </InputLabel>
                    </FormControl>{" "}
                    {childProfile.em_contact_ph}
                  </Typography>
                </Box>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={"20px"}
                mt={"10px"}
              >
                <Typography
                  sx={{
                    padding: "0 10px",
                    borderRadius: "5px",
                    width: isMobile ? "90%" : "70%",
                    margin: isMobile ? "0 auto" : "0 12.5%",
                    border: "1px solid #CBCBCB",
                  }}
                  variant="subtitle1"
                >
                  <Typography variant="h5" fontWeight={"bold"}>
                    Parent 1:
                  </Typography>{" "}
                  {parentInfo[0]?.parentFirstName} {parentInfo[0]?.parentLastName}
                </Typography>
                <Typography
                  sx={{
                    padding: "0 10px",
                    borderRadius: "5px",
                    width: isMobile ? "90%" : "70%",
                    margin: isMobile ? "0 auto" : "0 12.5%",
                    border: "1px solid #CBCBCB",
                  }}
                  variant="subtitle1"
                >
                  <Typography variant="h5" fontWeight={"bold"}>
                    Phone Number:
                  </Typography>{" "}
                  {parentInfo[0]?.parentPhone}{" "}
                </Typography>
                <Typography
                  sx={{
                    padding: "0 10px",
                    borderRadius: "5px",
                    width: isMobile ? "90%" : "70%",
                    margin: isMobile ? "0 auto" : "0 12.5%",
                    border: "1px solid #CBCBCB",
                  }}
                  variant="subtitle1"
                >
                  <Typography variant="h5" fontWeight={"bold"}>
                    Email:
                  </Typography>
                  {parentInfo[0]?.parentEmail}{" "}
                </Typography>
                <Typography
                  sx={{
                    padding: "0 10px",
                    borderRadius: "5px",
                    width: isMobile ? "90%" : "70%",
                    margin: isMobile ? "0 auto" : "0 12.5%",
                    border: "1px solid #CBCBCB",
                  }}
                  variant="subtitle1"
                >
                  <Typography variant="h5" fontWeight={"bold"}>
                    Parent 2:
                  </Typography>{" "}
                  {parentInfo[1]?.parentFirstName} {parentInfo[1]?.parentLastName}
                </Typography>
                <Typography
                  sx={{
                    padding: "0 10px",
                    borderRadius: "5px",
                    width: isMobile ? "90%" : "70%",
                    margin: isMobile ? "0 auto" : "0 12.5%",
                    border: "1px solid #CBCBCB",
                  }}
                  variant="subtitle1"
                >
                  <Typography variant="h5" fontWeight={"bold"}>
                    Phone Number:
                  </Typography>
                  {parentInfo[1]?.parentPhone}
                </Typography>
                <Typography
                  sx={{
                    padding: "0 10px",
                    borderRadius: "5px",
                    width: isMobile ? "90%" : "70%",
                    margin: isMobile ? "5px " : "0 12.5%",
                    border: "1px solid #CBCBCB",
                  }}
                  variant="subtitle1"
                >
                  <Typography variant="h5" fontWeight={"bold"}>
                    Email:
                  </Typography>
                  {parentInfo[1]?.parentEmail}
                </Typography>
                <Button onClick={handlePickUpModal}>Authorized to pick up</Button>
            </Box>
              {isMobile && (
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  sx={{
                    position: "sticky",
                    bottom: "0",
                    left: 0,
                    right: 0,
                    padding: "10px",
                    bgcolor: "white",
                  }}
                >
                  <Button onClick={handleClose}>Close</Button>
                  <Button onClick={handleEditMode}>Edit</Button>
                </Box>
              )}
              {authorizedPickupOpen && (
                <AuthorizedPickupModal
                  authorizedPickupOpen={authorizedPickupOpen}
                  setAuthorizedPickupOpen={setAuthorizedPickupOpen}
                  childProfileId={childProfile.id}
                  childProfile={childProfile}
                  refreshTable={refreshTable}
                  url={url}
                />
              )}
            </Box>
          </Stack>
        </Box>
      ) : (
        <Box
          className="childProfileModal"
          sx={{
            ...(childProfileModalOpen
              ? { display: "block", }
              : { display: "none" }), overflowY: "scroll", height: isMobile ? "80%" : "auto", padding: "20px 0"
          }}
        >
          <FormControl
            className="editModal"
            sx={{
              ml: isMobile ? "10%" : "20%",
              mt: "3%",
              gap: "25px",
              width: isMobile ? "90%" : "60%",
              margin: "0 15px" ,
              
            }}
            display={"flex"}
          >
            <TextField
              onChange={(e) =>
                setChild((prevChild) => ({
                  ...prevChild,
                  firstName: e.target.value,
                }))
              }
              label="First Name"
              defaultValue={childProfile.firstName}
            />
            <TextField
              onChange={(e) =>
                setChild((prevChild) => ({
                  ...prevChild,
                  lastName: e.target.value,
                }))
              }
              label="Last Name"
              defaultValue={childProfile.lastName}
            />
            <TextField
              onChange={(e) =>
                setChild((prevChild) => ({
                  ...prevChild,
                  birthday: e.target.value,
                }))
              }
              type="date"
              label="Birthday"
              defaultValue={childProfile.birthday}
            />
            <TextField
              onChange={(e) =>
                setChild((prevChild) => ({
                  ...prevChild,
                  em_contact_fn: e.target.value,
                }))
              }
              label="Emergency Contact First Name"
              defaultValue={childProfile.em_contact_fn}
            />
            <TextField
              onChange={(e) =>
                setChild((prevChild) => ({
                  ...prevChild,
                  em_contact_ln: e.target.value,
                }))
              }
              label="Emergency Contact Last Name"
              defaultValue={childProfile.em_contact_ln}
            />
            <TextField
              onChange={(e) =>
                setChild((prevChild) => ({
                  ...prevChild,
                  em_contact_ph: e.target.value,
                }))
              }
              label="Emergency Contact Phone"
              defaultValue={childProfile.em_contact_ph}
            />

            <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
              <Typography
                sx={{
                  padding: "0 10px",
                  borderRadius: "5px",
                  border: "1px solid #CBCBCB",
                  width: "auto",
                }}
                variant="subtitle1"
              >
                <Typography variant="h5" fontWeight={"bold"}>
                  Parent 1:
                </Typography>{" "}
                {parentInfo[0]?.parentFirstName} {parentInfo[0]?.parentLastName}
              </Typography>
              <Typography
                sx={{
                  padding: "0 10px",
                  borderRadius: "5px",
                  border: "1px solid #CBCBCB",
                }}
                variant="subtitle1"
              >
                <Typography variant="h5" fontWeight={"bold"}>
                  Phone Number:
                </Typography>{" "}
                {parentInfo[0]?.parentPhone}{" "}
              </Typography>
              <Typography
                sx={{
                  padding: "0 10px",
                  borderRadius: "5px",
                  border: "1px solid #CBCBCB",
                  width: "auto",
                }}
                variant="subtitle1"
              >
                <Typography variant="h5" fontWeight={"bold"}>
                  Email:
                </Typography>
                {parentInfo[0]?.parentEmail}{" "}
              </Typography>
              <Typography
                sx={{
                  padding: "0 10px",
                  borderRadius: "5px",
                  border: "1px solid #CBCBCB",
                  width: "auto",
                }}
                variant="subtitle1"
              >
                <Typography variant="h5" fontWeight={"bold"}>
                  Parent 2:
                </Typography>{" "}
                {parentInfo[1]?.parentFirstName} {parentInfo[1]?.parentLastName}
              </Typography>
              <Typography
                sx={{
                  padding: "0 10px",
                  borderRadius: "5px",
                  border: "1px solid #CBCBCB",
                  width: "auto",
                }}
                variant="subtitle1"
              >
                <Typography variant="h5" fontWeight={"bold"}>
                  Phone Number:
                </Typography>
                {parentInfo[1]?.parentPhone}
              </Typography>
              <Typography
                sx={{
                  padding: "0 10px",
                  borderRadius: "5px",
                  border: "1px solid #CBCBCB",
                  width: "auto",
                }}
                variant="subtitle1"
              >
                <Typography variant="h5" fontWeight={"bold"}>
                  Email:
                </Typography>{" "}
                {parentInfo[1]?.parentEmail}
              </Typography>
              <Box
                justifyContent={"space-between"}
                gap={"15px"}
                display={"flex"}
              >
                <Button onClick={handleSave}>Save</Button>
                <Button onClick={handleEditMode}>Cancel</Button>
              </Box>
            </Box>
          </FormControl>
        </Box>
      )}
    </Box>
  );
};

export default ChildProfileModal;
