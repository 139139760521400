import React, { useState } from "react";
import {
  TextField,
  Box,
  Typography,
  Divider,
  Card,
  CardContent,
  Grid,
  Switch,
  Select,
  MenuItem,
  FormControlLabel,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const Parents = ({
  sideMenuOpen,
  url,
  setParentSwitchStates,
  parentSwitchStates,
  parentData,
  childData,
  refreshTable,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [value, setValue] = useState("1");

  const handleChange = (parentId, event) => {
    event.stopPropagation();

    const parentIndex = parentData.findIndex(
      (parent) => parent.id === parentId
    );
    if (parentIndex === -1) {
      console.error(`Child with ID ${parentId} not found.`);
      return;
    }

    const isActive = !parentData[parentIndex].isActive;

    // Send PATCH request to update isActive
    fetch(`${url}/parents/${parentId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ isActive: isActive }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to update parent isActive status");
        }
        return response.json(); // Convert the response to JSON
      })
      .then((data) => {
        // Process the data
        console.log(
          `Parent ${parentId} isActive status updated successfully:`,
          data
        );
        refreshTable(); // Refresh table data
      })
      .catch((error) => {
        console.error("Error updating parent isActive status:", error);
      });
  };

  const handleDelete = (parentId) => {
    // Send DELETE request to remove the parent
    fetch(`${url}/parents/${parentId}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to delete parent");
        }
        console.log(`Parent ${parentId} deleted successfully`);
        refreshTable(); // Refresh table data
      })
      .catch((error) => {
        console.error("Error deleting parent:", error);
      });
  };

  return (
    <>
      <Box
        sx={{
          ...(isMobile
            ? { display: "flex", flexDirection: "column", alignItems: "center" }
            : {
                display: "flex",
                flexDirection: "row-reverse",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
              }),
        }}
      >
        <TextField
          fullWidth
          label="Search For Child"
          sx={{ maxWidth: "80%" }}
        />
        <Select
          sx={{
            ...(isMobile ? { width: "80%", margin: "20px 0 10px 0" } : {}),
          }}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        >
          <MenuItem value="1">Active Parents</MenuItem>
          <MenuItem value="2">Inactive Parents</MenuItem>
        </Select>
      </Box>
      <Divider sx={{ my: "20px", ...(sideMenuOpen ? { mx: "15px" } : {}) }} />
      <Box>
        {value === "1" ? (
          <Box
            sx={{
              ...(isMobile ? { maxHeight: "100%", overflowY: "scroll" } : {}),
            }}
          >
            <Grid container spacing={2}>
              {parentData &&
                parentData.map((parent) => {
                  return (
                    parent.isActive && (
                      <Grid item xs={12} sm={6} md={4} key={parent.id}>
                        <Card
                          sx={{
                            width: "95%",
                            maxWidth: isMobile ? "95%" : 400,
                            mx: "auto",
                          }}
                        >
                          <CardContent>
                            <Typography>
                              {parent.firstName} {parent.lastName}
                            </Typography>
                            <Typography>{parent.email}</Typography>
                            <Typography>{parent.streetAddress}</Typography>
                            <Typography>
                              {parent.city}, {parent.state} {parent.zipCode}
                            </Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  name={parent.firstName}
                                  onChange={(e) => handleChange(parent.id, e)}
                                  checked={parent.isActive}
                                />
                              }
                              label="Active Status"
                            />
                            <IconButton onClick={() => handleDelete(parent.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </CardContent>
                        </Card>
                      </Grid>
                    )
                  );
                })}
            </Grid>
          </Box>
        ) : value === "2" ? (
          <Box>
            <Grid container spacing={2}>
              {parentData &&
                parentData.map((parent) => {
                  return (
                    !parent.isActive && ( // Render only if parent is inactive
                      <Grid item xs={12} sm={6} md={4} key={parent.id}>
                        <Card
                          sx={{
                            width: "95%",
                            maxWidth: isMobile ? "100%" : 400,
                            mx: "auto",
                          }}
                        >
                          <CardContent>
                            <Typography>
                              {parent.firstName} {parent.lastName}
                            </Typography>
                            <Typography>{parent.email}</Typography>
                            <Typography>{parent.streetAddress}</Typography>
                            <Typography>
                              {parent.city}, {parent.state} {parent.zipCode}
                            </Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  name={parent.firstName}
                                  onChange={(e) => handleChange(parent.id, e)}
                                  checked={parent.isActive}
                                />
                              }
                              label="Active Status"
                            />
                            <IconButton onClick={() => handleDelete(parent.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </CardContent>
                        </Card>
                      </Grid>
                    )
                  );
                })}
            </Grid>
          </Box>
        ) : null}
      </Box>
      <Divider sx={{ my: "20px", ...(sideMenuOpen ? { mx: "15px" } : {}) }} />
    </>
  );
};

export default Parents;
