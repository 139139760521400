import React from "react";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';

const HourlyTable = ({ setHourlyData, url, refreshTable, hourlyData, hourlyModalOpen }) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const TableAnalytics = ({ hourlyData }) => {
        // Calculate the total payments by summing up all paymentAmounts
        const totalPayments = hourlyData.reduce((acc, curr) => acc + (curr.hoursWorked * curr.hourlyPay || 0), 0);
        const totalHours = hourlyData.reduce((acc, curr) => acc + (curr.hoursWorked || 0), 0);
        
        return (
          <Box 
          sx={{ 
            mt: 2, 
            mx: "1%", 
            display: 'flex', 
            justifyContent: 'space-between',
            position: "sticky", 
            bottom: 0, 
            ...(isMobile ? {
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              padding: "10px",
              backgroundColor: "#fff",
              borderTop: "1px solid #ccc",
              zIndex: 0.5
            } : {})
          }}
        >
            <Typography>Total Entries: {hourlyData.length}</Typography>
            <Box>
                <Typography>Total Hours: {totalHours}</Typography>
                <Typography>Total Pay: ${totalPayments.toFixed(2)}</Typography>
            </Box>
          </Box>
        );
    };
    
 
 
 
    const handleDelete = (id) => {
        fetch(`${url}/hourly_pay/${id}`, { method: "DELETE" })
          .then((res) => {
            if (!res.ok) {
              throw new Error("Something went wrong");
            }
            // Use functional update to ensure the latest state is used
            setHourlyData(hourlyData.filter((item) => item.id !== id));
          })
          .then(() => {
            refreshTable(); 
          })
          .then(() => {
            console.log(hourlyModalOpen)
            })
          .catch((error) => {
            alert("Couldn't delete payment: " + error.message);
          });
    };
    
  const columns = [
    {
      field: 'event',
      headerName: 'Event',
      headerClassName: 'tableheader',
      flex: 1,
      valueGetter: (params) =>
        `${params.row.event || ''}`,
    },
    {
      field: 'employeeName',
      headerName: 'Employee Name',
      headerClassName: 'tableheader',
      flex: 1,
      valueGetter: (params) =>
        `${params.row.employeeName || ''}`,
    },
    {
      field: 'date',
      headerName: 'Event Date',
      headerClassName: 'tableheader',
      flex: 1,
      valueGetter: (params) =>
        moment(params.row.date).format('MMMM D, YYYY'),
    },
    {
      field: 'hoursWorked',
      headerName: 'Hours Worked',
      headerClassName: 'tableheader',
      flex: 1,
      valueGetter: (params) =>
      `${params.row.hoursWorked || null}`
    },
    {
      field: 'hourlyPay',
      headerName: 'Pay Rate',
      headerClassName: 'tableheader',
      flex: 1,
      valueGetter: (params) =>
      (params.row.hourlyPay != undefined ? `$${params.row.hourlyPay}` : 'null')
    },
    {
        field: 'PaymentAmount',
        headerName: 'Payment Amount',
        headerClassName: 'tableheader',
        flex: 1,
        renderCell: (params) => (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <span>${params.row.hoursWorked * params.row.hourlyPay}</span>
            <ClearIcon
              onClick={() => handleDelete(params.id)}
              style={{ cursor: 'pointer' }}
            />
          </Box>
        ),
      }
  ];


    const rows = hourlyData.map((item) => {
     return item;
    })




    return (
        <>
      <div className="dataGridContainer" style={{ height: isMobile ? 'auto' : '100%', width: "98%", margin: "auto" }}>
        {!isMobile ? (<DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          autoHeight={isMobile}
        />) : (
<div>
  {hourlyData.map((item) => (
    <Box key={item.id} sx={{ mb: 2, position: "relative", width: "80vw" }} className="mobileCard">
      <Box >
      <Typography variant="body2" mb={1}>
        <strong>Date:</strong> {moment(item.date).format('MMMM D, YYYY')}
        </Typography>
      <Box sx={{display: "grid", gridTemplateColumns: "33% 33% 33%", gridTemplateRows: "50% 50%", gap: "5px 5px", width: "100%", alignItems: "center"}}>
          <Typography variant="body2" noWrap sx={{gridColumn: "1/2", gridRow: "1/2", width: "90%", margin: "0 auto"  }} >
            <strong>Event:</strong><br/> {`${item.event || ''}`}
          </Typography>
          <Typography variant="body2" noWrap sx={{gridColumn: "2/3", gridRow: "1/2", width: "90%", margin: "0 auto"  }} >
            <strong>Employee:</strong><br/> {`${item.employeeName || ''}`}
          </Typography>
          <Typography variant="body2" noWrap sx={{gridColumn: "1/2", gridRow: "2/3", width: "90%", margin: "0 auto"  }} >
            <strong>Expense:</strong><br/> {`${item.hoursWorked || ''}`}
          </Typography>
          <Typography variant="body2" noWrap sx={{gridColumn: "2/3", gridRow: "2/3", width: "90%", margin: "0 auto"  }}>
            <strong>Hourly Wage:</strong><br/> {`${item.hourlyPay || ''}`}
          </Typography>
    
          <Typography variant="body2" sx={{gridColumn: "3/4", gridRow: "2/3", width: "90%", margin: "0 auto"  }}>
            <strong>Amount:</strong><br/> {item.hourlyPay * item.hoursWorked}
          </Typography>
      </Box>
      </Box>
      <ClearIcon onClick={() => handleDelete(item.id)} style={{ cursor: 'pointer', position: "absolute", right: "10px", top: "10px" }} />
    </Box>
  ))}
</div>
        )
        }
      </div>
          <TableAnalytics hourlyData={hourlyData} />
        </>
      );
}

export default HourlyTable