import React, { useState } from "react";
import {
  Box,
  Stack,
  Typography,
  TextField,
  Select,
  MenuItem,
  Grid,
  Divider,
  FormControl,
  Button,
  InputLabel,
  useTheme,
  useMediaQuery,
} from "@mui/material";

const NewErollment = ({ url, parentData, childData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedParent1, setSelectedParent1] = useState(0);
  const [selectedParent2, setSelectedParent2] = useState(0);
  const [selectedChild1, setSelectedChild1] = useState(0);
  const [selectedChild2, setSelectedChild2] = useState(0);
  const [selectedChild3, setSelectedChild3] = useState(0);
  // Parent Information
  const [parents, setParents] = useState([
    {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      streetAddress: "",
      city: "",
      state: "",
      zipCode: "",
    },
    {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      streetAddress: "",
      city: "",
      state: "",
      zipCode: "",
    },
  ]);

  const [children, setChildren] = useState([
    {
      firstName: "",
      lastName: "",
      birthday: "",
    },
    {
      firstName: "",
      lastName: "",
      birthday: "",
    },
    {
      firstName: "",
      lastName: "",
      birthday: "",
    },
  ]);

  const [emergencyContact, setEmergencyContact] = useState({
    em_contact_fn: "",
    em_contact_ln: "",
    em_contact_ph: "",
  });
  const anyChildFilledIn = children.some(child => Object.values(child).some(value => value !== ''));

  const handleParent1Change = (e, field) => {
    setParents((prevParents) => {
      const updatedParent = { ...prevParents[0], [field]: e.target.value };
      return [updatedParent, prevParents[1]];
    });
  };

  const handleParent2Change = (e, field) => {
    setParents((prevParents) => {
      const updatedParent = { ...prevParents[1], [field]: e.target.value };
      return [prevParents[0], updatedParent];
    });
  };

  const handleChildChange = (e, index, field) => {
    setChildren((prevChildren) => {
      const updatedChild = { ...prevChildren[index], [field]: e.target.value };
      const newChildren = [...prevChildren];
      newChildren[index] = updatedChild;
      return newChildren;
    });
  };

  const handleEmergencyContactChange = (e, field) => {
    setEmergencyContact((prevEmergencyContact) => ({
      ...prevEmergencyContact,
      [field]: e.target.value,
    }));
  };

  const handleClearForm = () => {
    setParents([
      {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        streetAddress: "",
        city: "",
        state: "",
        zipCode: "",
      },
      {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        streetAddress: "",
        city: "",
        state: "",
        zipCode: "",
      },
    ]);
    setChildren([
      {
        firstName: "",
        lastName: "",
        birthday: "",
      },
      {
        firstName: "",
        lastName: "",
        birthday: "",
      },
      {
        firstName: "",
        lastName: "",
        birthday: "",
      },
    ]);
    setEmergencyContact({
      em_contact_fn: "",
      em_contact_ln: "",
      em_contact_ph: "",
    });
    setSelectedParent1(0);
    setSelectedParent2(0);
    setSelectedChild1(0);
    setSelectedChild2(0);
    setSelectedChild3(0);
  };

  const handleParentChange = (e) => {
    const selectedParentId = e.target.value; // Get the selected parent ID from the event
    const selectedParent = parentData.find(
      (parent) => parent.id === selectedParentId
    ); // Find the parent object corresponding to the selected ID

    if (Object.keys(selectedParent1).length === 0) {
      setSelectedParent1(selectedParent);
    } else {
      setSelectedParent2(selectedParent);
    }
  };

  const handleChildChangeSelector = (e) => {
    const selectedChildId = e.target.value; // Get the selected parent ID from the event
    const selectedChild = childData.find(
      (child) => child.id === selectedChildId
    ); // Find the parent object corresponding to the selected ID

    if (Object.keys(selectedChild1).length === 0) {
      setSelectedChild1(selectedChild);
    } else if (Object.keys(selectedChild2).length === 0) {
      setSelectedChild2(selectedChild);
    } else {
      setSelectedChild3(selectedChild);
    }
  };

  const handleSubmit = async () => {
    try {
      const prevParentDataResponse = await fetch(`${url}/parents`);
      const prevChildDataResponse = await fetch(`${url}/children`);

      const prevParentData = await prevParentDataResponse.json();
      const prevChildData = await prevChildDataResponse.json();
      const parentPostRequest = [
        Object.values(parents[0]).some((value) => value !== "")
          ? fetch(`${url}/parents`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(parents[0]),
            })
          : null,
        Object.values(parents[1]).some((value) => value !== "")
          ? fetch(`${url}/parents`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(parents[1]),
            })
          : null,
      ].filter(Boolean);

      const parentResponses = await Promise.all(parentPostRequest);
      const parentData = await Promise.all(
        parentResponses.map((res) => res.json())
      );
      console.log("All parent posts successful");

      const childPostRequest = [
        Object.values(children[0]).some((value) => value !== "")
          ? fetch(`${url}/children`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ ...children[0], ...emergencyContact }),
            })
          : null,
        Object.values(children[1]).some((value) => value !== "")
          ? fetch(`${url}/children`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ ...children[1], ...emergencyContact }),
            })
          : null,
        Object.values(children[2]).some((value) => value !== "")
          ? fetch(`${url}/children`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ ...children[2], ...emergencyContact }),
            })
          : null,
      ].filter(Boolean);

      const childResponses = await Promise.all(childPostRequest);
      const childData = await Promise.all(
        childResponses.map((res) => res.json())
      );
      console.log("All child posts successful");

      // Assuming childData contains child IDs

      // Fetch updated data if necessary
      const [updatedParentsData, updatedChildrenData] = await Promise.all([
        fetch(`${url}/parents`).then((res) => res.json()),
        fetch(`${url}/children`).then((res) => res.json()),
      ]);
      let newParents = {};
      let newChildren = {};
      // Assuming updatedParentsData and updatedChildrenData are arrays of updated data
      if (updatedParentsData.length > prevParentData.length) {
        newParents = updatedParentsData.filter(
          (parent) =>
            !prevParentData.some((prevParent) => prevParent.id === parent.id)
        );
      }
      if (updatedChildrenData.length > prevChildData.length) {
        newChildren = updatedChildrenData.filter(
          (child) =>
            !prevChildData.some((prevChild) => prevChild.id === child.id)
        );
      }

      const parentChildRelations = [];

      if (selectedParent1 || selectedParent2) {
        for (let child of newChildren) {
          if (selectedParent1) {
            parentChildRelations.push({
              parent_id: selectedParent1.id,
              parentFirstName: selectedParent1.firstName,
              parentLastName: selectedParent1.lastName,
              address: selectedParent1.streetAddress,
              city: selectedParent1.city,
              state: selectedParent1.state,
              zipCode: selectedParent1.zipCode,
              child_id: child.id,
            });
          }
          if (selectedParent2) {
            parentChildRelations.push({
              parent_id: selectedParent2.id,
              parentFirstName: selectedParent2.firstName,
              parentLastName: selectedParent2.lastName,
              address: selectedParent2.streetAddress,
              city: selectedParent2.city,
              state: selectedParent2.state,
              zipCode: selectedParent2.zipCode,
              child_id: child.id,
            });
          }
        }
      }
      if (selectedChild1 || selectedChild2 || selectedChild3) {
        for (let parent of newParents) {
          if (selectedChild1) {
            parentChildRelations.push({
              parent_id: parent.id,
              child_id: selectedChild1.id,
              childFirstName: selectedChild1.firstName,
              childLastName: selectedChild1.lastName,
            });
          }
          if (selectedChild2) {
            parentChildRelations.push({
              parent_id: parent.id,
              child_id: selectedChild2.id,
              childFirstName: selectedChild2.firstName,
              childLastName: selectedChild2.lastName,
            });
          }
          if (selectedChild3) {
            parentChildRelations.push({
              parent_id: parent.id,
              child_id: selectedChild3.id,
              childFirstName: selectedChild3.firstName,
              childLastName: selectedChild3.lastName,
            });
          }
        }
      }

      if (newParents.length > 0 && newChildren.length > 0) {
        for (let parent of newParents) {
          for (let child of newChildren) {
            parentChildRelations.push({
              parent_id: parent.id,
              child_id: child.id,
            });
          }
        }
      }

      // Now, after creating all parent-child relationships, make the POST request
      const parentChildPostRequests = await Promise.all(
        parentChildRelations.map((relation) => {
          return fetch(`${url}/parents_child`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(relation),
          });
        })
      );
    } catch (error) {
      console.error("There was a problem with one or more requests", error);
    }

    handleClearForm();
  };

  return (
    <Stack sx={{ ...(isMobile ? { overflowY: "scroll" } : {}) }}>
      <Box>
        <Typography ml={"20px"} variant={isMobile ? "h4" : "h3"}>
          Parent Information
        </Typography>
        <Divider />
        <Box sx={{ width: "98%", margin: "0 auto" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography ml={"20px"} variant="h5">
              Parent 1
            </Typography>
            <Select
              value={selectedParent1 ? selectedParent1.id : "0"}
              sx={{ marginRight: "2%", width: "20%" }}
              onChange={handleParentChange}
            >
              <MenuItem value={"0"}></MenuItem>
              {parentData.map((parent) => (
                <MenuItem key={parent.id} value={parent.id}>
                  {parent.firstName} {parent.lastName}
                  {" / "}
                  {parent.phoneNumber}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Grid container sx={{ gap: 1, my: 2, justifyContent: "center" }}>
            <Grid item lg={3.2} sm={5.5} xs={12}>
              <TextField
                fullWidth
                value={parents[0].firstName}
                onChange={(e) => handleParent1Change(e, "firstName")}
                label="Parent First Name"
              />
            </Grid>
            <Grid item lg={3.2} sm={5.5} xs={12}>
              <TextField
                fullWidth
                value={parents[0].lastName}
                onChange={(e) => handleParent1Change(e, "lastName")}
                label="Parent Last Name"
              />
            </Grid>
            <Grid item lg={2} sm={4} xs={12}>
              <TextField
                fullWidth
                value={parents[0].phoneNumber}
                onChange={(e) => handleParent1Change(e, "phoneNumber")}
                label="Phone Number"
              />
            </Grid>
            <Grid item lg={3} sm={7} xs={12}>
              <TextField
                fullWidth
                value={parents[0].email}
                onChange={(e) => handleParent1Change(e, "email")}
                label="email"
              />
            </Grid>
            <Grid item lg={4.4} sm={11.5} xs={12}>
              <TextField
                fullWidth
                value={parents[0].streetAddress}
                onChange={(e) => handleParent1Change(e, "streetAddress")}
                label="Street Address"
              />
            </Grid>
            <Grid item lg={3} sm={4} xs={12}>
              <TextField
                fullWidth
                value={parents[0].city}
                onChange={(e) => handleParent1Change(e, "city")}
                label="City"
              />
            </Grid>
            <Grid item lg={2} sm={4} xs={12}>
              <TextField
                fullWidth
                value={parents[0].state}
                onChange={(e) => handleParent1Change(e, "state")}
                label="State"
              />
            </Grid>
            <Grid item lg={2} sm={3} xs={12}>
              <TextField
                fullWidth
                value={parents[0].zipCode}
                onChange={(e) => handleParent1Change(e, "zipCode")}
                label="Zip Code"
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ width: "98%", margin: "0 auto" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography ml={"20px"} variant="h5">
              Parent 2
            </Typography>
            <Select
              value={selectedParent2 ? selectedParent2.id : "0"}
              onChange={handleParentChange}
              sx={{ marginRight: "2%", width: "20%" }}
            >
              <MenuItem value={"0"}></MenuItem>
              {parentData.map((parent) => (
                <MenuItem key={parent.id} value={parent.id}>
                  {parent.firstName} {parent.lastName}
                  {" / "}
                  {parent.phoneNumber}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Grid container sx={{ gap: 1, my: 2, justifyContent: "center" }}>
            <Grid item lg={3.2} sm={5.5} xs={12}>
              <TextField
                fullWidth
                value={parents[1].firstName}
                onChange={(e) => handleParent2Change(e, "firstName")}
                label="Parent First Name"
              />
            </Grid>
            <Grid item lg={3.2} sm={5.5} xs={12}>
              <TextField
                fullWidth
                value={parents[1].lastName}
                onChange={(e) => handleParent2Change(e, "lastName")}
                label="Parent Last Name"
              />
            </Grid>
            <Grid item lg={2} sm={4} xs={12}>
              <TextField
                fullWidth
                value={parents[1].phoneNumber}
                onChange={(e) => handleParent2Change(e, "phoneNumber")}
                label="Phone Number"
              />
            </Grid>
            <Grid item lg={3} sm={7} xs={12}>
              <TextField
                fullWidth
                value={parents[1].email}
                onChange={(e) => handleParent2Change(e, "email")}
                label="email"
              />
            </Grid>
            <Grid item lg={4.4} sm={11.5} xs={12}>
              <TextField
                fullWidth
                value={parents[1].streetAddress}
                onChange={(e) => handleParent2Change(e, "streetAddress")}
                label="Street Address"
              />
            </Grid>
            <Grid item lg={3} sm={4} xs={12}>
              <TextField
                fullWidth
                value={parents[1].city}
                onChange={(e) => handleParent2Change(e, "city")}
                label="City"
              />
            </Grid>
            <Grid item lg={2} sm={4} xs={12}>
              <TextField
                fullWidth
                value={parents[1].state}
                onChange={(e) => handleParent2Change(e, "state")}
                label="State"
              />
            </Grid>
            <Grid item lg={2} sm={3} xs={12}>
              <TextField
                fullWidth
                value={parents[1].zipCode}
                onChange={(e) => handleParent2Change(e, "zipCode")}
                label="Zip Code"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Divider sx={{ border: "3px solid", my: 2 }} />
      <Box sx={{ flexGrow: 1, gap: 1 }}>
        <Typography ml={"20px"} variant={isMobile ? "h4" : "h3"}>
          Child Information
        </Typography>
        <Divider />
        <Box sx={{ width: "98%", margin: "0 auto" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography ml={"20px"} variant="h5">
              Child 1
            </Typography>
            <Select
              value={selectedChild1 ? selectedChild1.id : "0"}
              onChange={handleChildChangeSelector}
              sx={{ marginRight: "2%", width: "20%" }}
            >
              <MenuItem value={"0"}></MenuItem>
              {childData.map((child) => (
                <MenuItem key={child.id} value={child.id}>
                  {child.firstName} {child.lastName}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Grid container sx={{ gap: 1, my: 2, justifyContent: "center" }}>
            <Grid item xs={12} sm={5.7}>
              <TextField
                fullWidth
                value={children[0].firstName}
                onChange={(e) => handleChildChange(e, 0, "firstName")}
                label="First Name"
              />
            </Grid>
            <Grid item xs={12} sm={5.7}>
              <TextField
                fullWidth
                value={children[0].lastName}
                onChange={(e) => handleChildChange(e, 0, "lastName")}
                label="Last Name"
              />
            </Grid>
            <Grid item xs={12} sm={11.45}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel
                  shrink
                  sx={{
                    bgcolor: "white",
                    px: 1,
                  }}
                >
                  DOB
                </InputLabel>
                <TextField
                  type="date"
                  value={children[0].birthday}
                  onChange={(e) => handleChildChange(e, 0, "birthday")}
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ width: "98%", margin: "0 auto" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography ml={"20px"} variant="h5">
              Child 2
            </Typography>
            <Select
              value={selectedChild2 ? selectedChild2.id : "0"}
              onChange={handleChildChangeSelector}
              sx={{ marginRight: "2%", width: "20%" }}
            >
              <MenuItem value={"0"}></MenuItem>
              {childData.map((child) => (
                <MenuItem key={child.id} value={child.id}>
                  {child.firstName} {child.lastName}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Grid container sx={{ gap: 1, my: 2, justifyContent: "center" }}>
            <Grid item xs={12} sm={5.7}>
              <TextField
                fullWidth
                value={children[1].firstName}
                onChange={(e) => handleChildChange(e, 1, "firstName")}
                label="First Name"
              />
            </Grid>
            <Grid item xs={12} sm={5.7}>
              <TextField
                fullWidth
                value={children[1].lastName}
                onChange={(e) => handleChildChange(e, 1, "lastName")}
                label="Last Name"
              />
            </Grid>
            <Grid item xs={12} sm={11.45}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel
                  shrink
                  sx={{
                    bgcolor: "white",
                    px: 1,
                  }}
                >
                  DOB
                </InputLabel>
                <TextField
                  type="date"
                  value={children[1].birthday}
                  onChange={(e) => handleChildChange(e, 1, "birthday")}
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ width: "98%", margin: "0 auto" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography ml={"20px"} variant="h5">
              Child 3
            </Typography>
            <Select
              value={selectedChild3 ? selectedChild3.id : "0"}
              onChange={handleChildChangeSelector}
              sx={{ marginRight: "2%", width: "20%" }}
            >
              <MenuItem value={"0"}></MenuItem>
              {childData.map((child) => (
                <MenuItem key={child.id} value={child.id}>
                  {child.firstName} {child.lastName}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Grid container sx={{ gap: 1, my: 2, justifyContent: "center" }}>
            <Grid item xs={12} sm={5.7}>
              <TextField
                fullWidth
                value={children[2].firstName}
                onChange={(e) => handleChildChange(e, 2, "firstName")}
                label="First Name"
              />
            </Grid>
            <Grid item xs={12} sm={5.7}>
              <TextField
                fullWidth
                value={children[2].lastName}
                onChange={(e) => handleChildChange(e, 2, "lastName")}
                label="Last Name"
              />
            </Grid>
            <Grid item xs={12} sm={11.45}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel
                  shrink
                  sx={{
                    bgcolor: "white",
                    px: 1,
                  }}
                >
                  DOB
                </InputLabel>
                <TextField
                  type="date"
                  value={children[2].birthday}
                  onChange={(e) => handleChildChange(e, 2, "birthday")}
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Divider sx={{ border: "3px solid", my: 2 }} />
      <Box>
        <Typography ml={"20px"} variant={isMobile ? "h4" : "h3"}>
          Emergency Contact
        </Typography>
        <Divider />

        <Grid container sx={{ gap: 1, my: 2, justifyContent: "center" }}>
          <Grid item xs={12} sm={5.5}>
            <TextField
              disabled={!anyChildFilledIn}
              fullWidth
              value={emergencyContact.em_contact_fn}
              onChange={(e) => handleEmergencyContactChange(e, "em_contact_fn")}
              label="First Name"
            />
          </Grid>
          <Grid item xs={12} sm={5.5}>
            <TextField
              disabled={!anyChildFilledIn}
              fullWidth
              value={emergencyContact.em_contact_ln}
              onChange={(e) => handleEmergencyContactChange(e, "em_contact_ln")}
              label="Last Name"
            />
          </Grid>
          <Grid item xs={12} sm={11}>
            <TextField
              disabled={!anyChildFilledIn}
              fullWidth
              value={emergencyContact.em_contact_ph}
              onChange={(e) => handleEmergencyContactChange(e, "em_contact_ph")}
              label="Phone Number"
            />
          </Grid>
        </Grid>

        <Box
          sx={{
            my: 2,
            ...(isMobile
              ? { textAlign: "center" }
              : { textAlign: "right", marginRight: "5%" }),
          }}
        >
          <Button
            sx={{ ...(isMobile ? { width: "90%" } : {}) }}
            onClick={handleSubmit}
            variant="contained"
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Stack>
  );
};

export default NewErollment;
