import React from "react";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';

const TableData = ({ tableData, url, setTableData, refreshTable }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const TableAnalytics = ({ tableData }) => {
    const totalPayments = tableData.reduce((acc, curr) => acc + parseFloat(curr.paymentAmount.slice(0) || 0), 0);
    return (
      <Box 
      sx={{ 
        mt: 2, 
        mx: "1%", 
        display: 'flex', 
        justifyContent: 'space-between',
        position: "sticky", 
        bottom: 0, 
        ...(isMobile ? {
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          padding: "10px",
          backgroundColor: "#fff",
          borderTop: "1px solid #ccc",
          zIndex: 0.5
        } : {})
      }}
    >
        <Typography>Total Entries: {tableData.length}</Typography>
        <Typography>Total Payments: {totalPayments.toFixed(2)}</Typography>
      </Box>
    );
  };

  const handleDelete = (id) => {
    fetch(`${url}/payments/${id}`, { method: "DELETE" })
      .then((res) => {
        if (!res.ok) {
          alert("someting went wrong");
        }
        setTableData(tableData.filter((item) => item.id !== id));
      })
      .then(() => {
        refreshTable();
      })
      .catch((error) => {
        alert("Couldnt Delete Payment", error);
      });
  };

  const columns = [
    {
      field: 'fullName',
      headerName: 'Child Name',
      headerClassName: 'tableheader',
      flex: isMobile ? 1 : 0.5,
      valueGetter: (params) => `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    },
    {
      field: 'parentName',
      headerName: 'Parent Name',
      headerClassName: 'tableheader',
      flex: isMobile ? 1 : 0.5,
      valueGetter: (params) => `${params.row.parentFirstName || ''} ${params.row.parentLastName || ''}`,
      hide: isMobile,
    },
    {
      field: 'paymentDate',
      headerName: 'Payment Date',
      headerClassName: 'tableheader',
      flex: isMobile ? 1 : 0.5,
      valueGetter: (params) => moment(params.row.paymentDate).format('MMMM D, YYYY'),
    },
    {
      field: 'paymentAmount',
      headerName: 'Payment Amount',
      headerClassName: 'tableheader',
      flex: isMobile ? 1 : 0.5,
      valueGetter: (params) => `${params.row.paymentAmount}`,
    },
    {
      field: 'paymentType',
      headerName: 'Payment Via',
      headerClassName: 'tableheader',
      flex: isMobile ? 1 : 0.5,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <span>{params.value}</span>
          <ClearIcon onClick={() => handleDelete(params.id)} style={{ cursor: 'pointer' }} />
        </Box>
      ),
    },
  ];

  const rows = tableData.map((item) => {
    return item;
  });

  return (
    <>
      <div className="dataGridContainer" style={{ height: isMobile ? 'auto' : '100%', width: "98%", margin: "auto" }}>
        {!isMobile ? (<DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          autoHeight={isMobile}
        />) : (
<div>
  {tableData.map((item) => (
    <Box key={item.id} sx={{ mb: 2, position: "relative", width: "80vw" }} className="mobileCard">
      <Box >
      <Typography variant="body2" mb={1}>
        <strong>Date:</strong> {moment(item.paymentDate).format('MMMM D, YYYY')}
        </Typography>
      <Box sx={{display: "grid", gridTemplateColumns: "50% 50%", gridTemplateRows: "50% 50%", gap: "5px 5px", width: "100%", alignItems: "center"}}>
          <Typography variant="body2" noWrap sx={{gridColumn: "1/2", gridRow: "1/2", width: "90%", margin: "0 auto"  }} >
            <strong>Child:</strong><br/> {`${item.firstName || ''} ${item.lastName || ''}`}
          </Typography>
          <Typography variant="body2" noWrap sx={{gridColumn: "2/3", gridRow: "1/2", width: "90%", margin: "0 auto"  }}>
            <strong>Parent:</strong><br/> {`${item.parentFirstName || ''} ${item.parentLastName || ''}`}
          </Typography>
    
          <Typography variant="body2" sx={{gridColumn: "1/2", gridRow: "2/3", width: "90%", margin: "0 auto"  }}>
            <strong>Amount:</strong><br/> {item.paymentAmount}
          </Typography>
            <Typography variant="body2" sx={{gridColumn: "2/3", gridRow: "2/3", width: "90%", margin: "0 auto"  }}>
              <strong>Type:</strong><br/> {item.paymentType}
            </Typography>
      </Box>
      </Box>
      <ClearIcon onClick={() => handleDelete(item.id)} style={{ cursor: 'pointer', position: "absolute", right: "10px", top: "10px" }} />
    </Box>
  ))}
</div>
        )
        }
      </div>
      <TableAnalytics tableData={tableData} />
    </>
  );
};

export default TableData;