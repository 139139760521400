import React from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Box, useTheme, useMediaQuery } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';


const Logout = ({sideMenuIsOpen, iconStyles}) => {
    const { logout, isAuthenticated } = useAuth0();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    isAuthenticated && (
    <Box textAlign={'center'}>
        {sideMenuIsOpen ? (<Button sx={{color: "blue", bgcolor: "white", ...isMobile ? {width: "80%" }: {width: "90%"}}} 
       variant='contained' onClick={() => logout()}>`Logout`</Button>) : (!isMobile && (<LogoutIcon onClick={() => logout()} sx={{fontSize: '40px', marginRight: "10px"}} />)) }
    </Box>
    )
  )
}

export default Logout