import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme, 
  useMediaQuery
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const AuthorizedPickupModal = ({
  authorizedPickupOpen,
  setAuthorizedPickupOpen,
  url,
  childProfileId,
  refreshTable,
  childProfile
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [relativeInfo, setRelativeInfo] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    relationship: "Select Relationship",
    child_id: "",
  });
  const [addRelativeOpen, setAddRelativeOpen] = useState(false);
    const [authorizedPickupData, setAuthorizedPickupData] = useState([]);
  const handleChange = (e, key) => {
    setRelativeInfo((prevState) => ({
      ...prevState,
      [key]: e.target.value,
    }));
  };

  const openAddRelative = () => {
    setAddRelativeOpen(true);
  };

  const handleCancel = () => {
    setAddRelativeOpen(false);
  };

  const handleClose = () => {
    setAuthorizedPickupOpen(false);
  };

  const handleAddRelative = () => {
    const { firstName, lastName, phoneNumber, relationship } = relativeInfo;
    fetch(`${url}/authorized_pickup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName,
        lastName,
        phoneNumber,
        relationship,
        child_id: childProfileId,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to add relative");
        }
        console.log(`Relative added successfully`);
      })
      .catch((error) => {
        console.error("Error adding relative:", error);
      });
    refreshTable();
    setAddRelativeOpen(false);
  };

  useEffect(() => {
   fetch(`${url}/authorized_pickup/${childProfileId}`)
      .then((res) => res.json())
      .then((data) => {
        setAuthorizedPickupData(data);
});
}, [refreshTable]);

const handleDeleteRelative = (id, relativeName) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete ${relativeName}?`);
    
    if (confirmDelete) {
        fetch(`${url}/authorized_pickup/${id}`, {
            method: "DELETE",
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to delete relative");
                }
                console.log(`Relative deleted successfully`);
            })
            .then(() => {
                refreshTable();
            })
            .catch((error) => {
                console.error("Error deleting relative:", error);
            });
    }
};


  return (
    <div className="authorizedPickupOverlay" 
    style={{ ...(authorizedPickupOpen ? { display: "block" } : {}) }}
    >
        <div
          className="authorizedPickupModal"
          style={{ ...(authorizedPickupOpen ? { display: "block" } : {}) }}
        >
          {!addRelativeOpen ? (
            <Box sx={{ height: "100%" }}>
              <Typography variant="h5" align="center">
                Authorized Pickups for {`${childProfile.firstName} ${childProfile.lastName}`} 
              </Typography>
              <Box
                position={"relative"}
                sx={{
                    bgColor: "white",
                    height: "70%",
                    width: "90%",
                    border: "2px solid black",
                }}
                mx={"auto"}
              >
                <Box
                    sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    height: "80%",
                    }}  
                >
                    {authorizedPickupData.map((relative) => {
                        return (
                            <Box
                                key={relative.id}
                                sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "0 10px",
                                border: "2px solid black",
                                }}
                            >
                                <Typography sx={{...isMobile ? {fontSize: "12px"} : {}, borderRight: "2px solid grey", paddingRight: "3px"}}>{`${relative.firstName} ${relative.lastName}`}</Typography>
                                <Typography sx={{...isMobile ? {fontSize: "12px"} : {}, borderRight: "2px solid grey", paddingRight: "3px"}}>{relative.phoneNumber}</Typography>
                                <Typography sx={{...isMobile ? {fontSize: "12px"} : {}, borderRight: "2px solid grey", paddingRight: "3px"}}>{relative.relationship}</Typography>
                                <Typography onClick={() => handleDeleteRelative(relative.id, (relative.firstName + " " + relative.lastName))} sx={{ textAlign: "right", borderRadius: "30%", padding: "2px" , "&:hover": {backgroundColor: "grey", color: "white", cursor: "pointer"}}}><DeleteIcon sx={{...isMobile ? {fontSize: "15px"} : {}}}/></Typography>
                            </Box>
                        );
                    }
                    )}
                </Box>
                <Box
                  display={"flex"}
                  position={"absolute"}
                  bottom={"0"}
                  borderTop={"2px solid black"}
                  width={"100%"}
                >
                  <Typography
                    sx={{
                      p: "0 12px",
                      fontSize: "25px",
                      border: "1px solid black",
                      "&:hover": {
                        backgroundColor: "black",
                        color: "white",
                        cursor: "pointer",
                      },
                    }}
                  >
                    -
                  </Typography>
                  <Typography
                    sx={{
                      p: "0 12px",
                      fontSize: "25px",
                      border: "1px solid black",
                      "&:hover": {
                        backgroundColor: "black",
                        color: "white",
                        cursor: "pointer",
                      },
                    }}
                    onClick={openAddRelative}
                  >
                    +
                  </Typography>
                </Box>
              </Box>
              <Button onClick={handleClose}>Close</Button>
            </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h5">Add Authorized Pickup</Typography>
              <FormControl
                sx={{ backgroundColor: "white", width: "80%", gap: "20px" }}
              >
                <TextField
                  onChange={(e) => handleChange(e, "firstName")}
                  sx={{ textAlign: "center" }}
                  label="First Name"
                />
                <TextField
                  onChange={(e) => handleChange(e, "lastName")}
                  label="Last Name"
                />
                <TextField
                  onChange={(e) => handleChange(e, "phoneNumber")}
                  label="Phone Number"
                />
                <Select
                  labelId="relationship"
                  value={relativeInfo.relationship} // Corrected variable name
                  onChange={(e) => handleChange(e, "relationship")}
                  sx={{ width: "100%" }}
                >
                  <MenuItem value="Select Relationship" disabled>
                    Select Relationship
                  </MenuItem>
                  <MenuItem value="Grandma">Grandma</MenuItem>
                  <MenuItem value="Grandpa">Grandpa</MenuItem>
                  <MenuItem value="Stepmom">Stepmom</MenuItem>
                  <MenuItem value="Stepdad">Stepdad</MenuItem>
                  <MenuItem value="Brother">Brother</MenuItem>
                  <MenuItem value="Sister">Sister</MenuItem>
                  <MenuItem value="Family Friend">Family Friend</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
              <Box>
                <Button onClick={handleAddRelative}>Submit</Button>
                <Button onClick={handleCancel}>Cancel</Button>
              </Box>
            </Box>
          )}
          
        </div>
    </div>
  );
};

export default AuthorizedPickupModal;
