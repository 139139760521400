import React from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Box, Typography, useTheme, useMediaQuery } from '@mui/material';

const Login = ({}) => {
    const { loginWithRedirect, isAuthenticated } = useAuth0();
  return (
    !isAuthenticated && (
    <Box sx={{textAlign: "center"}}>
        <Typography variant='h2' sx={{marginTop: "50%"}} >Welcome to Little Cubs Daycare</Typography>
        <Box sx={{width: '100vw', height: '100vh', textAlign: 'center'}}>
            <Button variant='contained'  onClick={() => loginWithRedirect()}>Login</Button>
        </Box>
    </Box>
    )
  )
}

export default Login