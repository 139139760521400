import "./App.css";
import React, { useState, useEffect } from "react";
import Layout from "./frontend/Components/Layout/Layout";
import PaymentTable from "./frontend/Components/Table/PaymentTable";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Parents from "./frontend/Components/Parent/Parents";
import Children from "./frontend/Components/Children/Children";
import NewEnrollment from "./frontend/Components/NewEnrollment/NewErollment";
import Login from "./frontend/Components/Login";
import Logout from "./frontend/Components/Logout";
import { useAuth0 } from "@auth0/auth0-react";

function App() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const [parentData, setParentData] = useState([]);
  const [childData, setChildData] = useState([]);
  const [parentSwitchStates, setParentSwitchStates] = useState({});
  const [childSwitchStates, setChildSwitchStates] = useState({});
  const [childProfileModalOpen, setChildProfileModalOpen] = useState(false);
  const [childProfile, setChildProfile] = useState({});
  const [parentChildData, setParentChildData] = useState([]);
  const [taxModal, setTaxModal] = useState(false);
  const [taxData, setTaxData] = useState([]);
  const [hourlyData, setHourlyData] = useState([]);
  const [hourlyModalOpen, setHourlyModalOpen] = useState(false);
  const url =
    process.env.NODE_ENV === "production"
      ? "https://payment-tracker-556f6c757b68.herokuapp.com"
      : "http://localhost:8080";

  const { isAuthenticated } = useAuth0();
  const { isLoading, error } = useAuth0();

  useEffect(() => {
    fetch(`${url}/payments`)
      .then((res) => res.json())
      .then((data) => setTableData(data));
  }, [refreshTableData]);

  useEffect(() => {
    fetch(`${url}/parents_child/association`)
      .then((res) => res.json())
      .then((data) => setParentChildData(data));

    fetch(`${url}/parents`)
      .then((res) => res.json())
      .then((data) => setParentData(data));

    fetch(`${url}/children`)
      .then((res) => res.json())
      .then((data) => setChildData(data));

    fetch(`${url}/writeoffs`)
      .then((res) => res.json())
      .then((data) => setTaxData(data));

    fetch(`${url}/hourly_pay`)
      .then((res) => res.json())
      .then((data) => setHourlyData(data));
  }, [refreshTableData]);

  const refreshTable = () => {
    setRefreshTableData(!refreshTableData);
  };

  if (isLoading) return <div>Loading...</div>;

  if (!isAuthenticated) {
    return <Login />;
  }

  return (
    <div className="App">
      <main className="column">
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Layout
                  setModalIsOpen={setModalIsOpen}
                  isOpen={modalIsOpen}
                  setTableData={setTableData}
                  tableData={tableData}
                  url={url}
                  refreshTable={refreshTable}
                  setSideMenuOpen={setSideMenuOpen}
                  sideMenuOpen={sideMenuOpen}
                  parentSwitchStates={parentSwitchStates}
                  childSwitchStates={childSwitchStates}
                  parentData={parentData}
                  childData={childData}
                  childProfileModalOpen={childProfileModalOpen}
                  setChildProfileModalOpen={setChildProfileModalOpen}
                  childProfile={childProfile}
                  setChildProfile={setChildProfile}
                  parentChildData={parentChildData}
                  taxModal={taxModal}
                  setTaxModal={setTaxModal}
                  hourlyModalOpen={hourlyModalOpen}
                  setHourlyModalOpen={setHourlyModalOpen}
                />
              }
            >
              <Route
                index
                element={
                  <PaymentTable
                    tableData={tableData}
                    url={url}
                    setTableData={setTableData}
                    setModalIsOpen={setModalIsOpen}
                    refreshTable={refreshTable}
                    taxData={taxData}
                    setTaxData={setTaxData}
                    taxModal={taxModal}
                    setTaxModal={setTaxModal}
                    hourlyData={hourlyData}
                    setHourlyData={setHourlyData}
                    setHourlyModalOpen={setHourlyModalOpen}
                    hourlyModalOpen={hourlyModalOpen}
                  />
                }
              />
              <Route
                path="/parents"
                element={
                  <Parents
                    url={url}
                    sideMenuOpen={sideMenuOpen}
                    setParentSwitchStates={setParentSwitchStates}
                    parentSwitchStates={parentSwitchStates}
                    parentData={parentData}
                    childData={childData}
                    refreshTable={refreshTable}
                    parentChildData={parentChildData}
                  />
                }
              />
              <Route
                path="/children"
                element={
                  <Children
                    sideMenuOpen={sideMenuOpen}
                    parentData={parentData}
                    setChildSwitchStates={setChildSwitchStates}
                    childSwitchStates={childSwitchStates}
                    childData={childData}
                    url={url}
                    refreshTable={refreshTable}
                    setChildProfileModalOpen={setChildProfileModalOpen}
                    setChildProfile={setChildProfile}
                    parentChildData={parentChildData}
                  />
                }
              />
              <Route
                path="/new_enrollment"
                element={
                  <NewEnrollment
                    url={url}
                    parentData={parentData}
                    childData={childData}
                    refreshTable={refreshTable}
                  />
                }
              />
            </Route>
          </Routes>
        </Router>
      </main>
    </div>
  );
}

export default App;
