import React from "react";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography , useTheme, useMediaQuery } from '@mui/material';

const WriteOffTable = ({ url, setTaxData, refreshTable, taxData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const TableAnalytics = ({ taxData }) => {
        const totaltaxWriteOff = taxData.reduce((acc, curr) => acc + (parseFloat(curr.amount) || 0), 0);

      
        return (
          <Box 
          sx={{ 
            mt: 2, 
            mx: "1%", 
            display: 'flex', 
            justifyContent: 'space-between',
            position: "sticky", 
            bottom: 0, 
            ...(isMobile ? {
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              padding: "10px",
              backgroundColor: "#fff",
              borderTop: "1px solid #ccc",
              zIndex: 0.5
            } : {})
          }}
        >
                <Typography>Total Entries: {taxData.length}</Typography>
                <Typography>Total Tax Exenses: ${totaltaxWriteOff.toFixed(2)}</Typography>
            </Box>
        );
    };
    
 
 
 
    const handleDelete = (id) => {
    fetch(`${url}/writeoffs/${id}`, { method: "DELETE" })
      .then((res) => {
        if (!res.ok) {
          alert("someting went wrong");
        }
        setTaxData(taxData.filter((item) => item.id !== id));
      })
      .then(() => {
        refreshTable();
      })
      .catch((error) => {
        alert("Couldnt Delete Payment", error);
      });
  };
  const columns = [
    {
      field: 'itemPurchased',
      headerName: 'Expense',
      headerClassName: 'tableheader',
      flex: 1,
      valueGetter: (params) =>
        `${params.row.itemPurchased || ''}`,
    },
    {
      field: 'storePurchasedFrom',
      headerName: 'Expense Type', 
      headerClassName: 'tableheader',
      flex: 1,
      valueGetter: (params) =>
        `${params.row.storePurchasedFrom || ''}`,

    },
    {
      field: 'purchaseDate',
      headerName: 'Expense Date',
      headerClassName: 'tableheader',
      flex: 1,
      valueGetter: (params) =>
        moment(params.row.paymentDate).format('MMMM D, YYYY'),
    },
    {
      field: 'amount',
      headerName: 'Amount (Before Tax)',
      headerClassName: 'tableheader',
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <span>{`$${params.value.toFixed(2)}`}</span>
          <ClearIcon
            onClick={() => handleDelete(params.id)}
            style={{ cursor: 'pointer' }}
          />
        </Box>
      )
    }
  ];


    const rows = taxData.map((item) => {
     return item;
    })




    return (
        <>
      <div className="dataGridContainer" style={{ height: isMobile ? 'auto' : '100%', width: "98%" }}>
        {!isMobile ? (<DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          autoHeight={isMobile}
        />) : (
<div>
  {taxData.map((item) => (
    <Box key={item.id} sx={{ mb: 2, position: "relative", width: "80vw" }} className="mobileCard">
      <Box >
      <Typography variant="body2" mb={1}>
        <strong>Date:</strong> {moment(item.purchaseDate).format('MMMM D, YYYY')}
        </Typography>
      <Box sx={{display: "grid", gridTemplateColumns: "50% 50%", gridTemplateRows: "50% 50%", gap: "5px 5px", width: "100%", alignItems: "center"}}>
          <Typography variant="body2" noWrap sx={{gridColumn: "1/2", gridRow: "1/2", width: "90%", margin: "0 auto"  }} >
            <strong>Expense:</strong><br/> {`${item.itemPurchased || ''}`}
          </Typography>
          <Typography variant="body2" noWrap sx={{gridColumn: "2/3", gridRow: "1/2", width: "90%", margin: "0 auto"  }}>
            <strong>Expense Type:</strong><br/> {`${item.storePurchasedFrom || ''}`}
          </Typography>
    
          <Typography variant="body2" sx={{gridColumn: "1/2", gridRow: "2/3", width: "90%", margin: "0 auto"  }}>
            <strong>Amount:</strong><br/> {item.amount}
          </Typography>
      </Box>
      </Box>
      <ClearIcon onClick={() => handleDelete(item.id)} style={{ cursor: 'pointer', position: "absolute", right: "10px", top: "10px" }} />
    </Box>
  ))}
</div>
        )
        }
      </div>
            <TableAnalytics taxData={taxData} />
        </>
      );
}

export default WriteOffTable